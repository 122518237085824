<template>
  <!-- 有偿征集 活动页面 -->
  <div>
    <div class="plan-banner">
      <div class="banner-title">
        <div class="main-title">LeetCode 思路解法有偿征集</div>
      </div>
    </div>
    <div class="container">
      <div class="page-title">LeetCode 思路解法有偿征集</div>
      <!-- <div class="create-time">2021.09.01 12:12:00</div> -->
      <div class="section-title">【 征集内容 】</div>
      <div class="section-content">
        <p>
          针对我们的LeetCode
          1-400题所有的题目解法，写文字版本的答案，代码我们已在网站公开显示，但没有文字版本的解答，所以我们打算有偿请广大用户提供文字版本的答案，以我们自己的代码为最终答案，写对应答案解释
        </p>
      </div>
      <div class="section-title">【 奖励内容 】</div>
      <div class="section-content">
        <p style="color: rgba(255, 59, 48, 1)">
          我们提供两种奖励方式，二者任选其一
        </p>
        <p>
          1. 金钱奖励 - 具体金额<span style="color: rgba(10, 122, 255, 1)"
            >请添加客服人员</span
          >详细解释
        </p>
        <p>
          2. 课程奖励
          <br />
          -
          <span style="color: rgba(10, 122, 255, 1)">30道题</span
          >，可以赠送<span style="color: rgba(10, 122, 255, 1)">30天</span
          >的<span style="color: rgba(10, 122, 255, 1)"
            >《LeetCode 千题视频讲解（1-300）》</span
          >；
          <br />

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -
          <span style="color: rgba(10, 122, 255, 1)">80道题</span
          >，可以赠送<span style="color: rgba(10, 122, 255, 1)">60天</span
          >的<span style="color: rgba(10, 122, 255, 1)"
            >《LeetCode 千题视频讲解（1-600）》</span
          >;
        </p>
        <p>发放规则： 课程奖励满30题才可发放，不满30题不能发放</p>
        <p style="color: rgba(255, 59, 48, 1)">
          本次活动的最终解释权归CSON官方所有。
        </p>
      </div>
      <div class="addwechat">
        <span
          >如有其他疑问，可咨询我们的工作人员
          <br />
          客服微信</span
        >
        <br />
        <img :src="qrcode.imageUrl" class="qrcode" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getCourseBanner } from "@/service";

export default {
  name: "interviewPlan",
  data() {
    return {
      qrcode: "",
    };
  },
  mounted() {
    this.getQrcodes();
    window.scrollTo(0, 0);
  },
  methods: {
    getQrcodes() {
      getCourseBanner(7)
        .then((res) => {
          console.log(res);
          if (!res.result.code) {
            this.qrcode = res.result[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {},
};
</script>
<style scoped>
.plan-banner {
  position: relative;
  background: url(../../assets/leetcode/activity.png) no-repeat center top;
  height: 300px;
}
.plan-banner img {
  width: 100%;
  vertical-align: top;
}
.banner-title {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
}
.main-title {
  font-size: 28px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  letter-spacing: 2px;
}
.sub-title1,
.sub-title2 {
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
  margin-bottom: 10px;
}
.container {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}
.page-title {
  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
}
.create-time {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 18px;
  margin-top: 16px;
}
.section-title {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(10, 122, 255, 1);
  line-height: 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  text-decoration: underline;
  text-decoration-color: rgba(10, 122, 255, 0.1);
}
.section-content p {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 18px;
  margin-bottom: 16px;
  line-height: 24px;
}
.interviewButton {
  text-align: center;
  margin-top: 100px;
}
.ibContainer {
  background-image: linear-gradient(to right, #fd1d1d, #fcb045);
  line-height: 44px;
  width: 300px;
  margin: 0 auto;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
}
.referDialog {
  z-index: 99 !important;
}
::v-deep .el-dialog__wrapper {
  z-index: 98 !important;
}
::v-deep .el-dialog__body {
  padding: 12px 20px;
}
.el-upload__tip {
  display: inline;
  margin-left: 12px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.addwechat {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 24px;
  text-align: center;
  margin-top: 60px;
}
.qrcode {
  width: 100px;
  height: 100px;
  border: 2px solid rgba(0, 117, 246, 1);
  border-radius: 4px;
}
</style>